<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="#005f32" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">SALARY COMPARISON REPORT</span>
          <v-spacer></v-spacer>
          <v-flex xs12 align-self-center pt-2>
            <v-layout wrap justify-start>
              <v-flex xs12 text-left>
                <span style="font-family: kumbhRegular; font-size: 14px">
                  *Select the options to filter the results
                </span>
              </v-flex>
              <v-flex xl3 lg4 md3 sm6 xs12 pr-2 pt-2 pt-lg-0 pt-sm-0 pt-md-0>
                <v-text-field
                  v-model="keyword"
                  outlined
                  dense
                  hide-details
                  clearable
                  label="Search Employee"
                ></v-text-field>
              </v-flex>
              <v-flex xl3 lg4 md3 sm6 xs12 pr-2 pt-2 pt-lg-0 pt-sm-0 pt-md-0>
                <v-autocomplete
                  v-model="positionid"
                  :items="positionList"
                  clearable
                  item-text="name"
                  item-value="_id"
                  dense
                  hide-details
                  outlined
                  label="Choose Position"
                >
                </v-autocomplete>
              </v-flex>
              <v-flex xl3 lg4 md3 sm6 xs12 pr-2 pt-2 pt-lg-0 pt-sm-5 pt-md-0>
                <v-autocomplete
                  v-model="gradeFilter"
                  :items="filteredGradeList"
                  clearable
                  item-text="grade"
                  item-value="_id"
                  outlined
                  dense
                  hide-details
                  label="Choose Grade"
                ></v-autocomplete>
              </v-flex>
              <v-flex xl3 lg4 md3 sm6 xs12 pr-2 pt-2 pt-lg-5 pt-sm-5 pt-md-0>
                <v-autocomplete
                  v-model="location"
                  :items="locationList"
                  clearable
                  outlined
                  dense
                  hide-details
                  label="Choose Location"
                ></v-autocomplete>
              </v-flex>
              <v-flex xl3 lg4 md3 sm6 xs12 pr-2 pt-2 pt-lg-5 pt-sm-5 pt-md-5>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromDate"
                      label="From Date"
                      outlined
                      hide-details
                      dense
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xl3 lg4 md3 sm6 xs12 pr-2 pt-2 pt-lg-5 pt-sm-5 pt-md-5>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="toDate"
                      label="To Date"
                      outlined
                      hide-details
                      dense
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDate"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xl3 lg4 md3 sm6 xs12 pr-2 pt-2 pt-lg-5 pt-sm-5 pt-md-5>
                <v-text-field
                  v-model="textqualification"
                  outlined
                  dense
                  hide-details
                  clearable
                  label="Qualification"
                ></v-text-field>
              </v-flex>
              <v-flex xl3 lg4 md3 sm6 xs12 pr-2 pt-2 pt-lg-5 pt-sm-5 pt-md-5>
                <v-text-field
                  v-model="totalexperience"
                  outlined
                  dense
                  type="number"
                  hide-details
                  clearable
                  label="Total Experience"
                ></v-text-field>
              </v-flex>
              <v-flex xl3 lg4 md3 sm6 xs12 pr-2 pt-2 pt-lg-5 pt-sm-5 pt-md-5>
                <v-text-field
                  v-model="texteducation"
                  outlined
                  dense
                  hide-details
                  clearable
                  label="Education"
                ></v-text-field>
              </v-flex>
              <!-- Checkboxes for filtering -->
              <!-- <v-flex xs12 pt-5>
                <v-layout wrap>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.salary"
                      label="Salary"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg3 xl2 >
                                  <v-checkbox v-model="filters.phone" label="Phone"></v-checkbox>
                                </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.pancard"
                      label="Pancard"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox v-model="filters.pmr" label="PMR"></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.grade"
                      label="Grade"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.qualification"
                      label="Qualification"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.bankdetails"
                      label="Bank Details"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.adhar"
                      label="Aadhar"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.PreExp"
                      label="Previous Experience"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.totalExp"
                      label="Total Experience"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.monthlyCTC"
                      label="Monthly CTC"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.education"
                      label="Education"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg2 xl2>
                    <v-checkbox
                      v-model="filters.disciplinaryAction"
                      label="Disciplinary Action"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-flex> -->
              <v-flex xs12 align-self-center text-end pt-6 v-if="data.length > 0">
                <v-btn
                  style="font-family: kumbhBold"
                  dark
                  color="#005f32"
                  @click="employeeExcelDownload()"
                >
                  Download Excel
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start px-4 v-if="data.length > 0">
          <v-flex xs12 pt-3>
            <v-simple-table class="align-center">
              <template v-slot:default>
                <thead style="background-color: #005f32">
                  <tr>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Employee Id
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Name
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Designation
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Date Of Joining
                    </th>

                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Exp With WTI
                    </th>

                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Exp Before WTI
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Total Exp
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Monthly CTC
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Education
                    </th>
                  </tr>
                </thead>
                <tbody class="align-center">
                  <template>
                    <tr
                      class="text-left align-center"
                      style="cursor: pointer"
                      v-for="item in data"
                      :key="item._id"
                    >
                      <td style="text-align: justify">
                        <span
                          v-if="item.employeeId"
                          class=""
                          style="font-family: kumbhMedium; cursor: pointer"
                        >
                          {{ item.employeeId }}
                        </span>
                        <span v-else>------</span>
                      </td>

                      <td>
                        <span
                          v-if="item.name"
                          class=""
                          style="font-family: kumbhMedium"
                          >{{ item.name }}</span
                        >
                        <span v-else>------</span>
                      </td>

                      <td>
                        <span
                          class=""
                          style="font-family: kumbhMedium"
                          v-if="item.positionDetails"
                          >{{ item.positionDetails[0].name }}</span
                        >
                        <span v-else>------</span>
                      </td>
                      <td>
                        <span
                          class=""
                          style="font-family: kumbhMedium"
                          v-if="item.dateofjoining"
                          >{{ formatDate(item.dateofjoining) }}</span
                        >
                        <span v-else>------</span>
                      </td>

                      <td>
                        <span
                          class=""
                          style="font-family: kumbhMedium"
                          v-if="item.yearsOfExperience"
                          >{{ item.yearsOfExperience }}</span
                        >
                      </td>
                      <td>
                        <span
                          class=""
                          style="font-family: kumbhMedium"
                          v-if="item.totalExperienceinYears"
                          >{{ item.totalExperienceinYears }}</span
                        >
                        <span v-else>------</span>
                      </td>
                      <td>
                        <span
                          class=""
                          style="font-family: kumbhMedium"
                          v-if="item.totalExperience"
                          >{{ item.totalExperience }}</span
                        >
                        <span v-else>------</span>
                      </td>
                      <td>
                        <span
                          class=""
                          style="font-family: kumbhMedium"
                          v-if="item.monthlyCTC"
                          >{{ item.monthlyCTC }}</span
                        >
                        <span v-else>------</span>
                      </td>
                      <td>
                        <span
                          class=""
                          style="font-family: kumbhMedium"
                          v-if="item.education"
                          >{{ item.education }}</span
                        >
                        <span v-else>------</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Data found!</span>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data: () => ({
    keyword: "",
    fromDate: "",
    toDate: "",
    menu1: false,
    menu2: false,
    positionid: "",
    gradeFilter: "",
    gradeFilterList: [],
    positionList: [],
    filters: {
      salary: false,
      //   phone: false,
      qualification: false,
      PreExp: false,
      totalExp: false,
      monthlyCTC: false,
      education: false,
      disciplinaryAction: false,
      salaryComparison: false,

      pancard: false,
      pmr: false,
      grade: false,
      bankdetails: false,
      adhar: false,
    },
    showsnackbar: false,
    ServerError: false,
    msg: null,
    appLoading: false,
    g: false,

    rules: {
      required: (value) => !!value || "Required.",
    },
    data: [],
    pages: 0,
    currentPage: 1,
    totalData: 0,
    locationList: [
      "HQ-Noida",
      "CWRC-Assam",
      "Dakopgre, Tura, Meghalaya",
      "Bansbari Field Station- Manas National Park - Assam",
      "Panbari",
      "Kaziranga Discovery Park",
      "Udanti Field Station, Chhattisgarh",
      "Arunachal Pradesh",
      "Similipal, Odisha",
      "Veraval- Gujarat",
      "Kokrajhar, Assam",
      "Field Station Charikhora, Kokrajhar (MVS) Park, Assam",
      "Edat Field Station, Kannur",
      "Valmiki Tiger Reserve, Bihar",
      "Nichlaul, Maharajganj, UP",
      "Dakopgre, Tura, Meghalaya",
      "Guijan, Assam",
      "Manas National Park - Assam",
      "Brahmpuri - Maharashtra",
      "Tirunelli - Wayanad, Kerala",
      "Field Camp Doimari, (Elephant Rehab), Manas National Park, Assam",
      "Ujjain",
      "Mithapur- Gujarat",
      "Hirpora, J&K",
      "Baripada, Similipal,Odisha",
      "Munnar,Kerala",
      "Gurguria",
      "Nichlaul, Maharajganj, UP",
      "Dakopgre, Tura, Meghalaya",
      "Panbari Field Station-CWRC",
      "Dakopgre, Tura, Meghalaya",
      "Baghmara",
      "Dudhwa",
      "Bandipur/Nagarhole",
      "J&K",
      "Keonjhar",
      "Kerala",
      "Katarniaghat",
      "Sanjay Tiger reserve",
      "Bandhavgarh",
      "Wayanad",
      "Kannur",
      "Manipur",
      "Pilibhit/Katerniaghat",
      "Central India",
      "Katarniaghat",
      "Gondia",
      "Assam",
      "Similipal, Odisha",
    ],
    location: "",
    totalexperience: "",
    texteducation: "",
    textqualification: "",
  }),
  computed: {
    filteredGradeList() {
      // Filter out items with null value
      return this.gradeFilterList.filter(
        (item, index) => index !== 0 && item._id !== null
      );
    },
  },

  mounted() {
    this.getData();
    if (this.position === "62a2de91ecd959516cd3b0e6") {
      this.getData();
    }
    this.getPositions();
    this.getGrades();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    totalexperience() {
      this.getData();
    },
    texteducation() {
      this.getData();
    },
    textqualification() {
      this.getData();
    },
    keyword() {
      this.getData();
      this.currentPage = 1;
    },
    positionid() {
      this.getData();
      this.currentPage = 1;
    },
    gradeFilter() {
      this.getData();
    },
    location() {
      this.getData();
    },
    fromDate() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },

    toDate() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
  },
  methods: {
    getGrades() {
      this.appLoading = true;
      axios({
        url: "/grade/dropdown/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        // params: {
        //   isDetailed: true,
        //   departmentIds: this.department,
        //   projectIds: this.project,
        // },
      })
        .then((response) => {
          this.appLoading = false;
          this.gradeFilterList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getPositions() {
      this.appLoading = true;
      axios({
        url: "/getalllist/position/v1",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        // params: {
        //   isDetailed: true,
        //   departmentIds: this.department,
        //   projectIds: this.project,
        // },
      })
        .then((response) => {
          this.appLoading = false;
          this.positionList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/report/employee/details/salaryComparison/list",
        method: "GET",
        params: {
          keyword: this.keyword,
          fromDate: this.fromDate,
          toDate: this.toDate,
          positionid: this.positionid,
          gradeFilter: this.gradeFilter,
          locationText: this.location,
          totalExpText: this.totalexperience,
          educationText: this.texteducation,
          qualificationText: this.textqualification,
          salaryComparison: true,
          page: this.currentPage,
          limit: 20,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.data = response.data.employees;
          this.totalData = response.data.total;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(date) {
      if (!date) return '';
      const dt = new Date(date);
      const day = String(dt.getDate()).padStart(2, '0');
      const month = String(dt.getMonth() + 1).padStart(2, '0');
      const year = dt.getFullYear();
      return `${day}-${month}-${year}`;
    },
    employeeExcelDownload() {
      axios({
        method: "GET",
        url: "/report/employee/details/salaryComparison/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          keyword: this.keyword,
          fromDate: this.fromDate,
          toDate: this.toDate,
          positionid: this.positionid,
          gradeFilter: this.gradeFilter,
          //   New filters
          locationText: this.location,
          totalExpText: this.totalexperience,
          educationText: this.texteducation,
          qualificationText: this.textqualification,
          salaryComparison: true,

          //   checkbox values
          //   salary: this.filters.salary,
          //   qualification: this.filters.qualification,
          //   PreExp: this.filters.PreExp,
          //   totalExp: this.filters.totalExp,
          //   monthlyCTC: this.filters.monthlyCTC,
          //   education: this.filters.education,
          //   disciplinaryAction: this.filters.qualification,
          //   salaryComparison: this.filters.salaryComparison,
          //   pancard: this.filters.pancard,
          //   pmr: this.filters.pmr,
          //   grade: this.filters.grade,
          //   bankdetails: this.filters.bankdetails,
          //   adhar: this.filters.adhar,
        },
        responseType: "blob", // Specify the response type as blob
      })
        .then((response) => {
          // Ensure the correct MIME type for Excel files
          const contentType =
            response.headers["content-type"] ||
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([response.data], { type: contentType });
          const url = window.URL.createObjectURL(blob);

          // Create a temporary anchor element
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.target = "_blank"; // Open in a new tab
          anchor.download = "Salary Comparison Report.xlsx"; // Set the file name

          // Programmatically click on the anchor to download the file
          anchor.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  <style>
.tableHead {
  font-size: 15px;
  color: #ffffff;
  font-family: kumbhMedium;
}
</style>